import login from "./login";
import ongs from "./ongs";
import platformFeatures from "./platformFeatures";
import brand from "./brand";
import otp from "./otp";
import { checkHash, recover, updatePasswordByHash } from "./recover";

export const apiLogin = login;
export const apiRecover = recover;
export const apiCheckRecoverHash = checkHash;
export const apiUpdatePasswordByHash = updatePasswordByHash;
export const apiOngs = ongs;
export const apiPlatformFeatures = platformFeatures;
export const apiBrand = brand;
export const apiOtp = otp;
