
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzModal from "@/components/Modal.vue";
  import { apiOngs } from "../api";
  import { apiOtp } from "../api";
  import { namespace } from "vuex-class";

  const auth = namespace("auth");

  const store = namespace("auth");

  @Component({
    components: { LzButton, LzModal }
  })
  export default class Signup extends Vue {
    otp = "";
    showOtp = false;
    signupForm = {
      name: "",
      email: "",
      password: "",
      mobilePhone: "",
      type: "",
      newsletter: false,
      nif: ""
    };

    organizationOptions = {
      ong: this.$t("auth.signup.form.organizations.ong"),
      limited: this.$t("auth.signup.form.organizations.limited"),
      research: this.$t("auth.signup.form.organizations.research"),
      art: this.$t("auth.signup.form.organizations.art"),
      other: this.$t("auth.signup.form.organizations.other")
    };

    loadingPostOngs = false;

    @store.Action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateData!: (payload: any) => void;

    @store.Action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateFeatures!: (payload: any) => void;

    @auth.State("ioCashUserId")
    public ioCashUserId!: string;

    sendOtp() {
      apiOtp(this.ioCashUserId, { otp: this.otp.toString() })
        .then((response: any) => {
          this.$notify({
            type: "success",
            text: this.$tc("auth.signup.notifications.success")
          });
          this.$router.push({ name: "Onboarding" });
        })
        .catch(err => {
          this.$notify({
            type: "error",
            text: this.$tc("auth.signup.notifications.failCode")
          });
          console.error(err);
        });
    }

    onSignupSubmit() {
      this.loadingPostOngs = true;
      apiOngs({
        name: this.signupForm.name,
        email: this.signupForm.email,
        password: this.signupForm.password,
        mobilePhone: this.signupForm.mobilePhone,
        type: this.signupForm.type,
        newsletter: this.signupForm.newsletter,
        nif: this.signupForm.nif
      })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          this.$notify({
            type: "success",
            text: this.$tc("auth.signup.notifications.success")
          });
          const { token, data } = response;
          if (token) {
            localStorage.setItem("accessToken", token);
            localStorage.setItem("auth", JSON.stringify(data));
            this.updateData(data);
            //this.updateFeatures(data.features);
            this.$router.push({ name: "Onboarding" });
          } else {
            throw new Error("No token included in the response");
          }
        })
        .catch(err => {
          this.$notify({
            type: "error",
            text: this.$tc("common.error.generic")
          });
          console.error(err);
        })
        .finally(() => {
          this.loadingPostOngs = false;
        });
    }
  }
